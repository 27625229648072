import {
  Card1,
  Card2,
  Card3,
  Card4,
  Card5,
  Card6,
  Card7,
  Card8,
} from "../utils/allImgs";
//////////////////////////////////// Main-product
export const stakingData = [
  {
    img: Card1,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card2,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card3,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card4,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card5,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card6,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card7,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card8,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card1,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card2,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card3,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card4,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card5,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card6,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card7,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card8,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card1,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card2,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card3,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card4,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card5,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card6,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card7,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card8,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card1,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card2,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card3,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card4,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card5,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card6,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card7,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Card8,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
];
