import { Plnt } from "../utils/allImgs";
//////////////////////////////////// Main-product
export const marketData = [
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
  {
    img: Plnt,
    title: "Scottu bear D...",
    percent: "150",
    lvl: "1.344",
    cmy: "107",
    bid: "150",
  },
];
