import "./assets/css/style.css";
import "./assets/css/modal.css";
import Routerapp from "./config/Router";
function App() {
  return (
    <div className="App">
      <Routerapp />
    </div>
  );
}

export default App;
